import { createContext } from 'react';
import RootStore from 'mobx/stores/root-store';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import GetSpaEnvironmentVariable from 'get-spa-environment-variable';

const redirectUri =
  GetSpaEnvironmentVariable('REACT_APP_FRONTEND_URL') ??
  process.env.REACT_APP_FRONTEND_URL;
const configuration: Configuration = {
  auth: {
    clientId:
      GetSpaEnvironmentVariable('REACT_APP_FRONTEND_CLIENT_ID') ??
      process.env.REACT_APP_FRONTEND_CLIENT_ID ??
      '',
    authority:
      'https://login.microsoftonline.com/ffc16ea6-34a8-4e2e-bc5c-dfc88a7b476b',
    redirectUri,
  },
};

export const publicClientApplication = new PublicClientApplication(
  configuration
);
export const rootStore = new RootStore(publicClientApplication);

export const RootStoreContext = createContext<RootStore>(rootStore);
