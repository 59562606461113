import { makeAutoObservable } from 'mobx';
import { AuthStore } from '@al-bank/al-store';
import { IPublicClientApplication } from '@azure/msal-browser';
import GetSpaEnvironmentVariable from 'get-spa-environment-variable';

class RootStore {
  authStore: AuthStore<this>;

  sleepTimeout?: number;

  resetErrorTimeout?: number;

  appSettings: { [name: string]: string };

  constructor(msalInstance: IPublicClientApplication) {
    makeAutoObservable(this);

    this.appSettings = {
      REACT_APP_APIM_URL:
        GetSpaEnvironmentVariable('REACT_APP_APIM_URL') ??
        process.env.REACT_APP_APIM_URL ??
        '',
      REACT_APP_IDENTITY_API_SCOPE:
        GetSpaEnvironmentVariable('REACT_APP_IDENTITY_API_SCOPE') ??
        process.env.REACT_APP_IDENTITY_API_SCOPE ??
        '',
      PUBLIC_URL:
        GetSpaEnvironmentVariable('PUBLIC_URL') ?? process.env.PUBLIC_URL ?? '',
    };
    this.authStore = new AuthStore(this, msalInstance);
  }
}

export default RootStore;
