import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { Navbar } from '@al-bank/al-nav-bar';
import {
  FaCoins,
  FaCheckDouble,
  FaArrowRight,
  FaList,
  FaFileExport,
  FaRegFlag,
} from 'react-icons/fa';
import './style.scss';

interface IApp {
  id: string;
  name: string;
  description: string;
  icon: ReactNode;
  url?: string;
}

const iconProps = {
  size: '5rem',
  color: '#af1e2d',
};
const apps: IApp[] = [
  {
    id: 'al-bilag',
    name: 'AL Bilag',
    description: 'Refusion af medarbejderes udlæg',
    icon: <FaCoins {...iconProps} />,
  },
  {
    id: 'al-eab',
    name: 'AL Efterkontrol af Bevillinger',
    description:
      'Efterkontrol af bevilgede og effektuerede lånesager via stikprøveprincip',
    icon: <FaCheckDouble {...iconProps} />,
  },
  {
    id: 'al-lister',
    name: 'AL Lister',
    description: 'Rapportering, Behandling og kontroller',
    icon: <FaList {...iconProps} />,
    url: 'https://lister.al-bank.dk',
  },
  {
    id: 'al-dokumentindsamling',
    name: 'AL Dokumentindsamling',
    description:
      'Indsamling af korrekte oplysninger og afsendelse til rette modtager, fx en kunde eller et andet system',
    icon: <FaFileExport {...iconProps} />,
    url: 'https://dokumentindsamling.al-bank.dk',
  },
  {
    id: 'al-pos-booking',
    name: 'AL POS-Booking',
    description: 'Bestilling af POS-artikler til filialaktiviteter',
    icon: <FaRegFlag {...iconProps} />,
    url: 'https://posbooking.al-bank.dk',
  },
];

const App = () => {
  return (
    <>
      <Navbar
        brand={{ url: '/', text: 'Interne applikationer' }}
        smallBrandOnly={false}
      />

      <div className="page">
        <Container className="gap-4 apps">
          {apps.map((a) => (
            <div
              className="p-4 d-flex flex-column gap-4 app-wrapper"
              key={a.id}
            >
              <div className="icon">{a.icon}</div>
              <h3 className="name">{a.name}</h3>
              <p className="description">{a.description}</p>
              <div className="px-2 pt-2 mt-auto">
                <a
                  href={a.url ? a.url : `https://app.al-bank.io/${a.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  <div className="d-flex align-items-center gap-4 link-content-wrapper">
                    <span className="link-text">Åbn applikation</span>
                    <span className="p-3 arrow-wrapper">
                      <FaArrowRight size="1rem" />
                    </span>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </>
  );
};

export default App;
