declare global {
  interface Window {
    appSettings: {
      [name: string]: string;
    };
  }
}

const GetSpaEnvironmentVariable = (key: string) => window.appSettings?.[key];

export default GetSpaEnvironmentVariable;
