import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { Spinner } from '@al-bank/al-spinner';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  RootStoreContext,
  rootStore,
  publicClientApplication,
} from 'mobx/contexts/root-store-context';
import App from './App';

import reportWebVitals from './reportWebVitals';

type IAuthWrapperProps = {
  children?: ReactNode;
};
const AuthWrapper = ({ children }: IAuthWrapperProps) => {
  return (
    <MsalProvider instance={publicClientApplication}>{children}</MsalProvider>
  );
};

const Auth = () => {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <>
      <UnauthenticatedTemplate>
        <Spinner text="Logger ind.." className="mt-3" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <RootStoreContext.Provider value={rootStore}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
            </Routes>
          </BrowserRouter>
        </RootStoreContext.Provider>
      </AuthenticatedTemplate>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthWrapper>
      <Auth />
    </AuthWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
